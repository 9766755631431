@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  font-family: 'Roboto', sans-serif;
}

/* Paleta culori 

2B2A4C
B31312
EA906C
EEE2DE
*/


/* =Header
-------------------------------------------------------------- */
header {
  width: 100%;
  height: 200px;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -ms-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s; 
}
  
header.smaller {
  height: 80px; 
}

header.smaller .header_top {
  display: none;
}

header.smaller .logo {
  margin-top: 0px;
}

header.smaller nav.navbar.navbar-expand-md.navbar-light {
  margin-top: 15px;
}

.header_top {
  background-color: #EEE2DE;
  padding: 15px 0px 0px;
  color: #2B2A4C;
  font-size: 15px;
  line-height: 1.1;
  font-weight: 700;
}

.header_top svg {
  color: #2B2A4C;
  float: left;
  margin-right: 20px;
  margin-top: 3px;
}

.header_top span {
  color: #B31312;
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 0;
  font-weight: 700;
}

.logo {
  margin-top: 10px;
}

nav.navbar.navbar-expand-md.navbar-light {
  margin-top:35px;
}

a {
  color: #111;
}

a:hover {
  color: #EA906C;
}

a.nav-link {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: #111;
  border-top: 2px solid #fff;
  font-weight: bold;
  padding: 5px;
  margin: 0px 8px;
}

a.nav-link:hover {
  color: #B31312;
  border-top: 2px solid #B31312;
}

.navbar-brand {
  display: none;
}

a.btn.btn-outline-primary.cere_pret {
  float: right;
  border-color: #B31312;
  color: #B31312;
}

a.btn.btn-outline-primary.cere_pret:hover {
  background-color: #B31312;
  color: #fff;
}

/* slider show */

.slideshow {
  background-color: #000;
  opacity: 1;
  min-height:100%;
  top: 190px;
  position: relative;
}

img.slider.d-block.w-100 {
  height: 600px;
  object-fit: fill;
  opacity: 0.4;
}

span.carousel-control-next-icon, span.carousel-control-prev-icon {
  width: 4rem;
  height: 4rem;
}

.carousel-caption {
  top: 35%
}

.carousel-caption span {
  font-size: 3.2rem;
  margin-bottom: 20px;
}

.carousel-caption .button {
  margin-top: 20px;
}

@media only screen and (max-width: 768px)  {
  .slideshow {
    top: 0px;
  }
  img.slider.d-block.w-100 {
    height: auto;
  }
  .carousel-caption {
    top: 7%
  }
  .carousel-caption span {
    font-size: 1.4rem;
  }
  .carousel-caption p {
    font-size: 0.9rem;
  }
  .slideshow .carousel-indicators {
    display: none;
  }
}

#banner {
  background-color: #1C6758;
  top: 250px;
}

#banner h1 {
  margin-top: 280px;
  position: absolute;
}

#banner span {
  font-family: 'Roboto', sans-serif;
  background-color: #0d322b;
  display: block;
  padding: 10px 25px;
  margin-top: 10px;
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}

span.ti-cursor {
  display: none;
}

span#color-1 {
    color: #EEE2DE;
}
button.instaleaza.btn.btn-primary.btn-lg {
  margin-top: 30px;
  background-color: #3D8361;
  border-color: #3D8361;
  color: #EEF2E6;
}

span.ti-cursor {
  display: inline-flex;
  margin-left: 30px;
}

#statistici {
  background-color: #B31312;
  color: #fff;
  font-size: 1.3em;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: 80px 0px;
  margin-top: 190px;
}

#statistici  svg {
  margin-bottom: 15px;
  margin-right: 15px;
}

#despre {
  padding: 130px 0px;
}

#despre span {
  color: #EA906C;
  text-transform: uppercase;
  font-size: 1.2em;
}

#despre h2 {
  color: #2B2A4C;
  text-transform: uppercase; 
  font-size: 2.8em;
  font-weight: 700;
  text-shadow: 5px 11px 1px #d4d4d4;
  margin-bottom: 30px;
}

#despre img {
  width: 100%;
}

.info_despre {
  color: #B31312; 
  font-size: 1.4rem;
  font-family: 'Roboto', sans-serif;
}

.info_despre svg {
  color: #B31312; 
  margin-right: 20px;
}

#quickcontact {
  position: fixed;
  right: 15px;
  bottom: 30px;
  z-index: 100;
}

.quickcontact_mobile svg {
  background-color: rgb(38, 123, 248);
  border-radius: 50%;
  color: #fff;
  padding: 15px;
  width: 30px;
  height: 30px;
  margin: 5px 0px;
}

.quickcontact_whatsapp svg {
  background-color: rgb(4, 231, 61);
  border-radius: 50%;
  color: #fff;
  padding: 15px;
  width: 30px;
  height: 30px;
  margin: 5px 0px;
}

#servicii {
  background-color: #EEE2DE;
  padding: 130px 0px;
}
.titlu_servicii {
  text-align: center;
}

.titlu_servicii span {
  color: #B31312;
  text-transform: uppercase;
  font-size: 1.2em;
}

.titlu_servicii h2 {
  color: #2B2A4C;
  text-transform: uppercase;
  font-size: 2.8em;
  font-weight: 700;
  text-shadow: 4px 4px 0px #d4d4d4;
  margin-bottom: 60px;
}

.serviciu {
  background-color: #B31312;
  display: inline;
  padding: 40px;
  color: #1C6758;
}

.serviciu:hover {
  background-color: #EEE2DE;
  color: #2B2A4C;
}

.serviciu img {
  float: right;
}

.serviciu h3 {
  color: #fff;
  font-size: 1rem;
  line-height: 42px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 15px;
  transition: all 0.3s linear;
  font-weight: 700;
}

.serviciu svg {
  color: #EEE2DE;
}

.serviciu:hover svg, .serviciu:hover h3 {
  color: #B31312;
}

#video {
  background-color: #EEE2DE;
  padding: 0px 0px 120px;
}

#testimoniale {
  padding: 130px 0px 75px;
  background-color: #B31312;
  color: #EEE2DE;
}

#testimoniale svg {
  float: left;
  margin-right: 20px;
  color: #EA906C;
}

#testimoniale img {
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 50px;
}

#portofoliu {
  background-color: #EEE2DE;
  padding: 130px 0px;
}

.image-row {
  text-align: center;
}

.image-set img {
  padding: 10px;
}

.calltoaction {
  background-color: #EA906C;
  padding: 100px 50px;
  color: #111;
}

.calltoaction p {
  border-bottom: 2px solid #fff;
  max-width: 200px;
  line-height: 2.4rem;
}
.calltoaction h3 {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 800;
}

.calltoaction a {
  text-decoration: none;
  color: #111;
}

.calltoaction svg {
  margin-right: 20px;
}

button.btn.btn-success.calltoaction {
  float: right;
  margin-top: 30px;
  padding: 20px;
}

button.btn.btn-success.calltoaction:hover {
  background-color: #B31312;
}

button.btn.btn-success.calltoaction:hover svg, button.btn.btn-success.calltoaction:hover a {
  color: #fff;
}

footer {
  background-color: #2B2A4C;
  padding: 100px 0px;
}

.footer_logo {
  background-color: #fff;
}
.footer_block {
  background-color: #EEE2DE;
}
.footer_block:hover {
  background-color: #B31312;
  color: #fff;
}
.footer_block svg {
  margin-bottom: 20px;
}
.footer_logo, .footer_block {
  padding: 40px;
  text-align: center;
}
.social_media svg {
  color: #EEE2DE;
  margin-right: 10px;
}
.social_media svg:hover {
  color: #EEF2E6;
}

.footer_info {
  margin-top: 30px;
  color: #EEF2E6;
}

.footer_info h5 {
  color: #EA906C;
  margin-bottom: 30px;
}

.footer_info h5::after {
  content: '';
  display: block;
  width: 150px;
  height: 2px;
  position: relative;
  top: 20px;
  background-color: #EA906C;
  margin-bottom: 45px;
}

.footer_info a {
  color: #fff;
  text-decoration: none;
}
.footer_info a:hover {
  color: #EEE2DE;
}
.footer_info li:hover {
  color: #EEE2DE;
  cursor: pointer; 
}
.footer_info ul {
  padding: 0;
}
.footer_info li {
  list-style-type: none;
  padding: 5px 0px;
}

@media (min-width: 820px) and  (max-width: 1024px) {
  header {
    position: relative;
  }
  #banner h1 {
    margin-top:80px;
  }
  #despre img,  #video img, #banner img {
    max-width: 100%;
    width: auto;
  }
  .logo {
    margin-top: 13px;
    width: auto;
    max-width: 230px;
  }
  a.nav-link {
    font-size: 13px;
  }
  a.btn.btn-outline-primary.cere_pret {
    float: none;
  }
  .serviciu img {
    width: auto;
  }
}
@media (max-width: 768px) {
  header {
    min-height: 330px;
    position: relative;
  }
  .logo {
    margin-top: 10px;
  }
  .logo.col-lg-4.col-md-4.col-12 {
    text-align: center;
  }
  #banner h1 {
    margin-top: 20px;
  }
  #banner span {
    font-size: 22px;
  }
  #statistici p {
    margin-bottom: 3rem;
  }
  nav.navbar.navbar-expand-md.navbar-light {
    display: none;
  }
  .header_top {
    font-size: 12px;
  }
  .header_top svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  .email_header, .telefon_header {
    text-align: center;
  }
  .email_header svg, .telefon_header svg{
    display: none;
  }
  a.btn.btn-outline-primary.cere_pret {
    float: none;
    text-align: center;
    width: 100%;
    margin: 10px 0px;
    border-color: #B31312;
    color: #111;
  }
  a.btn.btn-outline-primary.cere_pret:hover {
    background-color: #B31312;
    color: #EEF2E6;
  }
  .navbar-toggler{
    border: 0;
  }
  #banner span {
    line-height: 50px;
    padding: 10px
  }
  #banner img {
    width: 100%;
  }
  #video img {
    width: 100%;
  }
  .info_despre {
    font-size: 1.2rem;
  }
  .info_despre svg {
    margin-top: 20px 0px;
  }
  button.btn.btn-success.calltoaction {
    padding: 15px;
    float: none;
  }
  .calltoaction h3 {
    font-size: 30px;
  }
  .image-set img {
    max-width: 170px;
    width: 100%;
	}
  footer {
    padding: 0;
  }
  .footer_info h5 {
    margin-bottom: 30px 0px;
  }
  .footer_info h5::after {
    margin: 0px auto 40px auto;
  }
  .titlu_servicii h2 {
    font-size: 2em;
  }
  #portofoliu, .calltoaction, #testimoniale, #despre, #statistici {
    padding: 70px 0px;
  }
  .calltoaction, #despre, .footer_info,  #testimoniale, .serviciu {
    text-align: center;
  }
  .calltoaction p {
    max-width: 100%;
    border: 0;
  }
  #despre img {
    margin-bottom: 40px;
  }
  #video {
    padding: 20px 0px;
  }
  #servicii {
    padding: 70px 0px 0px;
  }
  #statistici {
    margin-top: 0px;
  }
}
@media (min-width: 280px) and  (max-width: 320px) {
  #banner span {
    line-height: 20px;
    padding: 5px;
  }
  .logo {
    margin-top: 10px;
    width: auto;
    max-width: 100%;
  }
  .header_top span {
    font-size: 12px;
  }
  .footer_logo img {
    width: 100%;
  }
  header {
    min-height: 300px;
  }
  #statistici {
    margin-top: 0px;
  }
}
